import React, { Component } from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import Onboarding from "../components/accounts/Onboarding";
import { Mutation } from "react-apollo";
import SubmitButton from "../components/accounts/SubmitButton";
import { navigate } from "gatsby";
import gql from "graphql-tag";
import Prototyping from "../components/apps/Prototyping";
import { onboardingWidth } from "../Theme";
import PrivateRoute from "../components/auth/PrivateRoute";

const styles = (theme: Theme) =>
  createStyles({
    wide: {
      maxWidth: onboardingWidth
    },
    button: {
      width: 400
    }
  });

interface Props extends WithStyles<typeof styles> {}

const mutation = gql`
  mutation MoveToDashboard {
    updateOnboarding(currentStep: "first-steps", nextStep: "dashboard") {
      id
      onboarding
    }
  }
`;

export class FirstSteps extends Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <Onboarding
        classes={{ root: classes.wide }}
        title="Let's start with the basics"
        subtitle="You can get building right away using our client library. Here are some of the things you can do with Certane."
      >
        <Prototyping onboarding />

        <Mutation mutation={mutation} onCompleted={this.onComplete}>
          {(updateOnboarding, { loading }) => (
            <SubmitButton
              label="Continue to Dashboard"
              disabled={loading}
              onClick={() => updateOnboarding()}
              className={classes.button}
            />
          )}
        </Mutation>
      </Onboarding>
    );
  }

  private onComplete = () => {
    navigate("/");
  };
}

const FirstStepsPage = withStyles(styles)(FirstSteps);
export default () => <PrivateRoute component={FirstStepsPage} />;
